import classnames from 'classnames';
import { ReactElement, useCallback } from 'react';

import CarouselItemHorizontalCard from './CarouselItemHorizontalCard/CarouselItemHorizontalCard';
import cardStyles from './CarouselItemHorizontalCard/CarouselItemHorizontalCard.module.scss';
import Carousel from 'components/Carousel/Carousel';
import CarouselModuleHeader from 'components/contentfulModules/CarouselModuleHeader/CarouselModuleHeader';
import IDefaultComponentProps from 'components/defaultComponentProps';
import LocalizableLink from 'components/LocalizableLink/LocalizableLink';

import { CarouselHeaderLayout, ImageCarouselModuleEntry } from 'lib/contentful';

import styles from './HorizontalCardImageCarouselModule.module.scss';

type HorizontalCardImageCarouselModuleProps = {
  imageCarouselModuleEntry: ImageCarouselModuleEntry;
} & IDefaultComponentProps;

const HorizontalCardImageCarouselModule = (
  props: HorizontalCardImageCarouselModuleProps
): ReactElement => {
  const {
    displayLinksForItems,
    headerData,
    itemSize = 'small',
    items,
    link,
  } = props.imageCarouselModuleEntry.fields;
  const isHeaderEmbedded =
    headerData && headerData.fields.layout === CarouselHeaderLayout.embedded;

  const carouselHeader = useCallback(() => {
    return (
      <>
        {headerData && isHeaderEmbedded && (
          <div className={cardStyles.root}>
            <CarouselModuleHeader headerEntry={headerData} />
          </div>
        )}
      </>
    );
  }, [headerData, isHeaderEmbedded]);

  const carouselItems = useCallback(() => {
    return (
      <>
        {items.map((item, index) => (
          <CarouselItemHorizontalCard
            carouselItem={item}
            isHeaderEmbedded={isHeaderEmbedded}
            itemSize={itemSize}
            key={item.sys.id}
            position={index}
            showLink={displayLinksForItems}
          />
        ))}
      </>
    );
  }, [displayLinksForItems, isHeaderEmbedded, itemSize, items]);

  return (
    <div
      className={classnames(styles.root, props.className)}
      {...props.dataAttributes}
    >
      {headerData && !isHeaderEmbedded && (
        <CarouselModuleHeader headerEntry={headerData} />
      )}
      <Carousel
        cardsContainerClassName={styles.cardsContainer}
        classNameForCenteringArrows={cardStyles.image}
      >
        {carouselHeader()}
        {carouselItems()}
      </Carousel>
      {link && !isHeaderEmbedded && (
        <LocalizableLink className={styles.carouselLink} {...link.fields} />
      )}
    </div>
  );
};

export default HorizontalCardImageCarouselModule;
