import classnames from 'classnames';
import { ReactElement } from 'react';

import ContentfulImage from 'components/contentfulImage/ContentfulImage';
import { buildAllImageAssets } from 'components/contentfulModules/utils';
import LocalizableLink from 'components/LocalizableLink/LocalizableLink';

import { CarouselImageSizes } from 'lib/carouselImageSizes';
import { ImageCarouselModuleItemEntry } from 'lib/contentful';
import { getUrlFromAsset } from 'lib/contentful/utils';

import styles from './CarouselItemCircle.module.scss';

const buildImageSrcs = (imageUrl: string) => {
  // when the image is a circle, we need to specify a height and crop the image to enforce 1:1 aspect ratio.
  const src = {
    fit: 'crop',
    height: CarouselImageSizes.extraSmall,
    url: imageUrl,
    width: CarouselImageSizes.extraSmall,
  };
  const smallSrc = {
    fit: 'crop',
    height: CarouselImageSizes.small,
    url: imageUrl,
    width: CarouselImageSizes.small,
  };
  const mediumSrc = {
    fit: 'crop',
    height: CarouselImageSizes.medium,
    url: imageUrl,
    width: CarouselImageSizes.medium,
  };
  const largeSrc = {
    fit: 'crop',
    height: CarouselImageSizes.large,
    url: imageUrl,
    width: CarouselImageSizes.large,
  };
  const xLargeSrc = {
    fit: 'crop',
    height: CarouselImageSizes.extraLarge,
    url: imageUrl,
    width: CarouselImageSizes.extraLarge,
  };

  return { largeSrc, mediumSrc, smallSrc, src, xLargeSrc };
};

type CarouselItemHorizontalCardProps = {
  carouselItem: ImageCarouselModuleItemEntry;
  itemSize: 'small' | 'large';
};

const CarouselItemCircle = (
  props: CarouselItemHorizontalCardProps
): ReactElement => {
  const { carouselItem, itemSize } = props;
  const { image, link, title } = carouselItem.fields;
  const imageUrl = getUrlFromAsset(image);
  const imageSrcs = buildImageSrcs(imageUrl);

  return (
    <div
      className={classnames(styles.root, styles.circle, styles[itemSize])}
      key={carouselItem.sys.id}
    >
      <LocalizableLink
        {...link.fields}
        className={styles.imageContainer}
        displayText={title || 'Link'}
      >
        <ContentfulImage
          alt={carouselItem.fields.title || 'Link'}
          assets={buildAllImageAssets(image)}
          className={styles.image}
          {...imageSrcs}
        />
      </LocalizableLink>

      {title && <div className={styles.title}>{title}</div>}
    </div>
  );
};

export default CarouselItemCircle;
