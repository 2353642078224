import classnames, { Argument } from 'classnames';
import { ReactElement } from 'react';

import CtaButton from 'components/Buttons/CtaButton';
import PrimaryButton from 'components/Buttons/PrimaryButton';
import SecondaryButton from 'components/Buttons/SecondaryButton';
import TextButton from 'components/Buttons/TextButton/TextButton';
import LocalizableLink from 'components/LocalizableLink/LocalizableLink';

import {
  ButtonThemeEnum,
  ButtonTypeEnum,
  UltimateButtonEntry,
} from 'lib/contentful';
import Logger from 'lib/utils/Logger';

import styles from './UltimateButton.module.scss';

export type UltimateButtonProps = {
  customStyles?: Argument;
} & UltimateButtonEntry;

const UltimateButton = (props: UltimateButtonProps): ReactElement | null => {
  try {
    const { buttonTheme, buttonType, link, text } = props.fields;
    const customStyles = props.customStyles;

    const renderButton = (): ReactElement => {
      if (buttonType === ButtonTypeEnum.textLink) {
        return (
          <TextButton
            className={classnames(
              buttonTheme === ButtonThemeEnum.secondary
                ? styles.blueText
                : styles.light,
              styles.shared,
              customStyles
            )}
          >
            {text}
          </TextButton>
        );
      } else if (buttonTheme === ButtonThemeEnum.primary) {
        return (
          <PrimaryButton className={classnames(styles.shared, customStyles)}>
            {text}
          </PrimaryButton>
        );
      } else if (buttonTheme === ButtonThemeEnum.cta) {
        return (
          <CtaButton className={classnames(styles.shared, customStyles)}>
            {text}
          </CtaButton>
        );
      }

      return (
        <SecondaryButton className={classnames(styles.shared, customStyles)}>
          {text}
        </SecondaryButton>
      );
    };

    return (
      <LocalizableLink className={styles.root} {...link.fields}>
        {renderButton()}
      </LocalizableLink>
    );
  } catch (error) {
    Logger.error('Something went run rendering UltimateButton', error);
    return null;
  }
};

export default UltimateButton;
