import classnames from 'classnames';

import CarouselItemCard from './CarouselItemCard/CarouselItemCard';
import cardStyles from './CarouselItemCard/CarouselItemCard.module.scss';
import Carousel from 'components/Carousel/Carousel';
import IDefaultComponentProps from 'components/defaultComponentProps';
import LocalizableLink from 'components/LocalizableLink/LocalizableLink';

import { ImageCarouselModuleEntry } from 'lib/contentful';

import styles from './CardImageCarouselModule.module.scss';

import CarouselModuleHeader from '../../CarouselModuleHeader/CarouselModuleHeader';

type CardImageCarouselModuleProps = {
  imageCarouselModuleEntry: ImageCarouselModuleEntry;
  itemClassName?: string;
  itemImageClassName?: string;
  itemLinkClassName?: string;
} & IDefaultComponentProps;

const CardImageCarouselModule = (props: CardImageCarouselModuleProps) => {
  const {
    imageCarouselModuleEntry,
    itemClassName,
    itemImageClassName,
    itemLinkClassName,
  } = props;
  const { headerData, items, link } = imageCarouselModuleEntry.fields;

  return (
    <div
      className={classnames(styles.root, props.className)}
      {...props.dataAttributes}
    >
      {headerData && <CarouselModuleHeader headerEntry={headerData} />}
      <Carousel
        cardsContainerClassName={styles.cardsContainer}
        classNameForCenteringArrows={cardStyles.image}
      >
        {items.map((item, index) => (
          <CarouselItemCard
            carouselItem={item}
            className={itemClassName}
            classNameImage={itemImageClassName}
            classNameLink={itemLinkClassName}
            key={item.sys.id}
            position={index}
          />
        ))}
      </Carousel>
      {link && (
        <LocalizableLink className={styles.carouselLink} {...link.fields} />
      )}
    </div>
  );
};

export default CardImageCarouselModule;
