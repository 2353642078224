import classnames from 'classnames';
import upperFirst from 'lodash/upperFirst';

import { FontFamilyEnum, UltimateTextEntry } from 'lib/contentful';
import Logger from 'lib/utils/Logger';

import styles from './UltimateText.module.scss';

export type UltimateTextProps = {
  prefix?: string;
} & UltimateTextEntry;

const UltimateText = (props: UltimateTextProps) => {
  try {
    const { fields, prefix } = props;
    const {
      color,
      fontFamily = FontFamilyEnum.sans,
      size,
      text,
      weight,
    } = fields;

    return (
      <span
        className={classnames(
          styles.shared,
          styles[`size${upperFirst(size)}`],
          styles[`weight${upperFirst(weight)}`],
          styles[`fontFamily${upperFirst(fontFamily)}`]
        )}
        style={{ color }}
      >
        {prefix}
        {text}
      </span>
    );
  } catch (error) {
    Logger.error(
      `Something went wrong rendering UltimateText with props ${JSON.stringify(
        props
      )}`,
      error
    );
    return null;
  }
};

export default UltimateText;
