import Modal, { ModalProps } from 'components/Modals/Modal/Modal';
import VimeoPlayer from 'components/VimeoPlayer/VimeoPlayer';

import styles from './VimeoModal.module.scss';

type VimeoModalProps = {
  videoId: number;
} & Omit<ModalProps, 'children'>;

const VimeoModal = (props: VimeoModalProps) => {
  const { header, isOpen, onCloseClick, videoId } = props;

  return (
    <div>
      <Modal
        className={styles.modal}
        header={header}
        isOpen={isOpen}
        onCloseClick={onCloseClick}
      >
        <VimeoPlayer
          playerOptions={{ id: videoId, playsinline: true, responsive: true }}
        />
      </Modal>
    </div>
  );
};

export default VimeoModal;
