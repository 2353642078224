import { Component } from 'react';

import CardImageCarouselModule from './CardImageCarouselModule/CardImageCarouselModule';
import CircleImageCarouselModule from './CircleImageCarouselModule/CircleImageCarouselModule';
import HorizontalCardImageCarouselModule from './HorizontalCardImageCarouselModule/HorizontalCardImageCarouselModule';

import {
  ImageCarouselModuleEntry,
  ImageCarouselModuleStyles,
} from 'lib/contentful';

import { ContentfulModuleProps } from '../sharedTypes';

type ImageCarouselModuleProps = {
  itemClassName?: string;
  itemImageClassName?: string;
  itemLinkClassName?: string;
} & ContentfulModuleProps<ImageCarouselModuleEntry>;

class ImageCarouselModule extends Component<ImageCarouselModuleProps> {
  render() {
    const { itemStyle } = this.props.entry.fields;

    if (
      itemStyle === ImageCarouselModuleStyles.cardHorizontal ||
      itemStyle === ImageCarouselModuleStyles.largeCardHorizontal
    ) {
      return (
        <HorizontalCardImageCarouselModule
          className={this.props.className}
          dataAttributes={this.props.dataAttributes}
          imageCarouselModuleEntry={this.props.entry}
        />
      );
    }

    if (itemStyle === ImageCarouselModuleStyles.circle) {
      return (
        <CircleImageCarouselModule
          className={this.props.className}
          dataAttributes={this.props.dataAttributes}
          imageCarouselModuleEntry={this.props.entry}
        />
      );
    }

    // Defaults to the card carousel
    return (
      <CardImageCarouselModule
        className={this.props.className}
        dataAttributes={this.props.dataAttributes}
        imageCarouselModuleEntry={this.props.entry}
        itemClassName={this.props.itemClassName}
        itemImageClassName={this.props.itemImageClassName}
        itemLinkClassName={this.props.itemLinkClassName}
      />
    );
  }
}

export default ImageCarouselModule;
