import classnames from 'classnames';
import idx from 'idx';

import IDefaultComponentProps from 'components/defaultComponentProps';
import LocalizableLink from 'components/LocalizableLink/LocalizableLink';

import {
  CarouselHeaderEntry,
  CarouselHeaderLayout,
  HeaderType,
} from 'lib/contentful';

import styles from './CarouselModuleHeader.module.scss';

/**
 * A header component that can be used by different carousel modules.
 *
 * There are three layouts:
 *  1. stacked - header and subheader are stacked on top of each other.
 *  2. inline  - the header and subheader are in the same row
 *  3. embedded - the component is rendered within a carousel card
 */

type ModuleHeaderProps = {
  headerEntry: CarouselHeaderEntry;
} & IDefaultComponentProps;

const CarouselModuleHeader = (props: ModuleHeaderProps) => {
  const { className } = props;
  const headerFields = idx(props, _ => _.headerEntry.fields);
  if (!headerFields) {
    return null;
  }
  const { headerText, headerType, layout, link, subHeaderText } = headerFields;
  const isLayoutEmbedded = layout === CarouselHeaderLayout.embedded;
  const isLayoutInline = layout === CarouselHeaderLayout.inline;

  if (headerType === HeaderType.h1) {
    return (
      <h1
        className={classnames(styles.root, className, {
          [styles.embedded]: isLayoutEmbedded,
          [styles.inline]: isLayoutInline,
        })}
      >
        {headerText && <div className={styles.header}>{headerText}</div>}
        {subHeaderText && (
          <div className={styles.subHeader}>{subHeaderText}</div>
        )}
        {isLayoutEmbedded && <div className={styles.divider}>—</div>}
        {link && (
          <div className={styles.link}>
            <LocalizableLink {...link.fields} className={styles.link} /> &#8250;
          </div>
        )}
      </h1>
    );
  } else {
    return (
      <h2
        className={classnames(styles.root, className, {
          [styles.embedded]: isLayoutEmbedded,
          [styles.inline]: isLayoutInline,
        })}
      >
        {headerText && <div className={styles.header}>{headerText}</div>}
        {subHeaderText && (
          <div className={styles.subHeader}>{subHeaderText}</div>
        )}
        {isLayoutEmbedded && <div className={styles.divider}>—</div>}
        {link && (
          <div className={styles.link}>
            <LocalizableLink {...link.fields} className={styles.link} /> &#8250;
          </div>
        )}
      </h2>
    );
  }
};

export default CarouselModuleHeader;
