import classnames from 'classnames';
import { useCallback, useState, ReactElement } from 'react';

import Image from 'components/Image/Image';
import LocalizableLink from 'components/LocalizableLink/LocalizableLink';
import VimeoModal from 'components/VimeoModal/VimeoModal';

import { Breakpoints } from 'lib/breakpoints';
import { ImageCarouselModuleItemEntry } from 'lib/contentful';
import { getUrlFromAsset } from 'lib/contentful/utils';
import keyPressHandler from 'lib/ui/keyPress/keyPressHandler';

import PlayIcon from 'assets/icons/ic-play.inline.svg';

import styles from './CarouselItemHorizontalCard.module.scss';

const xSmallWidth = Breakpoints.widthSmall / 2;
const smallWidth = Breakpoints.widthMedium / 4;
const mediumWidth = Breakpoints.widthExtraLarge / 4;

type CarouselItemHorizontalCardProps = {
  carouselItem: ImageCarouselModuleItemEntry;
  isHeaderEmbedded?: boolean;
  itemSize: 'small' | 'large';
  position: number;
  showLink?: boolean;
};

const CarouselItemHorizontalCard = ({
  carouselItem,
  isHeaderEmbedded,
  itemSize = 'small',
  position,
  showLink,
}: CarouselItemHorizontalCardProps): ReactElement => {
  const { image, link, subTitle, title, videoId } = carouselItem.fields;

  const imageUrl = getUrlFromAsset(image);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const closeModal = useCallback(() => setIsModalOpen(false), []);

  const imageComponent = (
    <Image
      className={styles.image}
      mediumSrc={{ url: imageUrl, width: mediumWidth }}
      smallSrc={{ url: imageUrl, width: smallWidth }}
      src={{ url: imageUrl, width: xSmallWidth }}
    />
  );

  const cardComponent = videoId ? (
    <div className={styles.videoContainer}>
      <div className={styles.iconContainer}>
        <div className={styles.iconWrapper}>
          <PlayIcon className={styles.icon} />
        </div>
      </div>

      <div className={styles.imageContainer}>{imageComponent}</div>

      <VimeoModal
        header={title}
        isOpen={isModalOpen}
        onCloseClick={closeModal}
        videoId={videoId}
      />
    </div>
  ) : (
    <LocalizableLink {...link.fields} className={styles.imageContainer}>
      {imageComponent}
    </LocalizableLink>
  );

  return (
    <div
      className={classnames(styles.root, styles[itemSize], {
        [styles.withEmbeddedHeader]: isHeaderEmbedded,
      })}
      data-card-position={position}
      onClick={() => setIsModalOpen(true)}
      onKeyPress={keyPressHandler({
        Enter: () => {
          setIsModalOpen(true);
        },
      })}
      role="button"
      tabIndex={0}
    >
      {cardComponent}
      {title && <div className={styles.title}>{title}</div>}
      {subTitle && <div className={styles.subTitle}>{subTitle}</div>}
      {link && showLink && (
        <LocalizableLink {...link.fields} className={styles.link} />
      )}
    </div>
  );
};

export default CarouselItemHorizontalCard;
