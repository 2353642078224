import classnames from 'classnames';
import { ReactElement } from 'react';

import CarouselItemCircle from './CarouselItemCircle/CarouselItemCircle';
import cardStyles from './CarouselItemCircle/CarouselItemCircle.module.scss';
import Carousel from 'components/Carousel/Carousel';
import IDefaultComponentProps from 'components/defaultComponentProps';
import LocalizableLink from 'components/LocalizableLink/LocalizableLink';

import { ImageCarouselModuleEntry } from 'lib/contentful';

import styles from './CircleImageCarouselModule.module.scss';

type CircleImageCarouselModuleProps = {
  imageCarouselModuleEntry: ImageCarouselModuleEntry;
} & IDefaultComponentProps;

const CircleImageCarouselModule = ({
  className,
  dataAttributes,
  imageCarouselModuleEntry,
}: CircleImageCarouselModuleProps): ReactElement => {
  const {
    headerData,
    itemSize = 'small',
    items,
    link,
  } = imageCarouselModuleEntry.fields;

  return (
    <div className={classnames(styles.root, className)} {...dataAttributes}>
      {headerData && (
        <div className={styles.header}>{headerData.fields.headerText}</div>
      )}
      <Carousel
        cardsContainerClassName={styles.cardsContainer}
        classNameForCenteringArrows={cardStyles.image}
      >
        {items.map(item => (
          <CarouselItemCircle
            carouselItem={item}
            itemSize={itemSize}
            key={item.sys.id}
          />
        ))}
      </Carousel>
      {link && (
        <LocalizableLink className={styles.carouselLink} {...link.fields} />
      )}
    </div>
  );
};

export default CircleImageCarouselModule;
