import Player, { Options as PlayerOptions } from '@vimeo/player';
import { useEffect, useRef } from 'react';

/**
 * A React wrapper for the Vimeo Player
 * Documentation for player: https://github.com/vimeo/player.js
 * Documentation for playerOptions: https://github.com/vimeo/player.js#embed-options
 */

type VimeoPlayerProps = {
  className?: string;
  playerOptions: PlayerOptions;
};

const VimeoPlayer = (props: VimeoPlayerProps) => {
  const { className, playerOptions } = props;
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    let player: Player;

    if (containerRef.current) {
      player = new Player(containerRef.current, playerOptions);
    }

    return () => {
      if (player) {
        player.destroy();
      }
    };
  }, [containerRef]);

  return <div className={className} ref={containerRef} />;
};

export default VimeoPlayer;
